import Axios from "axios";
import { getActiveToken } from '../helpers/jwtDecode'

Axios.interceptors.request.use(request => {
    request.baseURL = process.env.REACT_APP_URL
    if (typeof (request.data) !== 'object') {
        request.headers = {
            'Content-Type': 'application/json',
            'Authorization': getActiveToken()
        }
    } else {
        request.headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': getActiveToken()
        }
    }
    return request;
}, error => {
    return Promise.reject(error);
});
Axios.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error);
});
export const fetchJSON = (url, options = {}) => {
    options.url = url;
    if (url.split('/').pop() === 'Export') {
        options.responseType = "blob"
    }

    return Axios(options)
        .then(response => {
            if (response.status >= 200 && response.status <300) {
                return response.data;

            } else  {
                throw response;
            }
           
        })
        .catch(error => {
            throw error.response;
        });
};
export const optionPost = (body) => {
    return { data: JSON.stringify(body), method: 'POST' };
}
export const optionPatch = (body) => {
    return { data: JSON.stringify(body), method: 'PATCH' };
}
export const optionGet = (body = null) => {
    return { params: body, method: 'GET' };
}
export const optionDelete = (body) => {
    return { data: JSON.stringify(body), method: 'DELETE' };
}