import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as constants from './constant'
import * as action from './action'
import { fetchJSON, optionGet } from '../../ApiUtils/apiUtils';
import { handleError } from '../../ApiUtils/handleError';
import { listUrl } from '../../ApiUtils/listUrl';
function* UsersCount({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, `${listUrl.usersCountUser}`, optionGet(body));         
        yield put(action.UsersCountSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.UsersCountFailed(message));
    }
}
function* CountUserByCountry({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, `${listUrl.usersCountUserByCountry}`, optionGet(body));         
        yield put(action.UsersCountByCountrySuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.UsersCountByCountryFailed(message));
    }
}
function* CountUserByCity({ payload: { body } }) {
    try {
        console.log(body)
        const response = yield call(fetchJSON, `${listUrl.usersCountUserByCity}`, optionGet(body));         
        yield put(action.UsersCountByCitySuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.UsersCountByCityFailed(message));
    }
}
function* UsersGet({ payload: { body } }) {
    console.log(body)
    try {
        const response = yield call(fetchJSON, `${listUrl.usersGetList}`, optionGet(body));         
        yield put(action.Users_GetSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.Users_GetFailed(message));
    }
}
export function* watchUsersCount() {
    yield takeEvery(constants.Users_Count, UsersCount);
}
export function* watchUsersCountByCountry() {
    yield takeEvery(constants.Users_Count_By_Country, CountUserByCountry);
}
export function* watchUsersCountByCity() {
    yield takeEvery(constants.Users_Count_By_City, CountUserByCity);
}
export function* watchUsersGet() {
    yield takeEvery(constants.Users_Get, UsersGet);
}
function* Saga() {
    yield all([
        fork(watchUsersCountByCountry),
        fork(watchUsersCountByCity),
        fork(watchUsersCount),
        fork(watchUsersGet),
       
    ]);
}
export default Saga;