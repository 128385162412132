import React from 'react'
import './assets/scss/them.scss'
import Routes from './routes/Routes';
const App = () => {
    return (
        <React.Suspense fallback={<></>}>
            <Routes />           
        </React.Suspense>);
}
export default App;
