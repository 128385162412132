import * as constants from './constant'

export const  UsersCount= (body) => ({
    type: constants.Users_Count,
    payload: { body },
});

export const UsersCountSuccess = (user) => ({
    type: constants.Users_Count_SUCCESS,
    payload: user,
});

export const UsersCountFailed = (error) => ({
    type: constants.Users_Count_FAILED,
    payload: error,
});
export const  UsersCountByCountry= (body) => ({
    type: constants.Users_Count_By_Country,
    payload: { body },
});

export const UsersCountByCountrySuccess = (user) => ({
    type: constants.Users_Count_By_Country_SUCCESS,
    payload: user,
});

export const UsersCountByCountryFailed = (error) => ({
    type: constants.Users_Count_By_Country_FAILED,
    payload: error,
});
export const  UsersCountByCity= (body) => ({
    type: constants.Users_Count_By_City,
    payload: { body },
});

export const UsersCountByCitySuccess = (user) => ({
    type: constants.Users_Count_By_City_SUCCESS,
    payload: user,
});

export const UsersCountByCityFailed = (error) => ({
    type: constants.Users_Count_By_City_FAILED,
    payload: error,
});

export const  Users_Get= (body) => ({
    type: constants.Users_Get,
    payload: { body },
});

export const Users_GetSuccess = (user) => ({
    type: constants.Users_Get_SUCCESS,
    payload: user,
});

export const Users_GetFailed = (error) => ({
    type: constants.Users_Get_FAILED,
    payload: error,
});
