export const Users_Count = 'Users_Count';
export const Users_Count_SUCCESS = 'Users_Count_SUCCESS';
export const Users_Count_FAILED = 'Users_Count_FAILED';

export const Users_Count_By_Country = 'Users_Count_By_Country';
export const Users_Count_By_Country_SUCCESS = 'Users_Count_By_Country_SUCCESS';
export const Users_Count_By_Country_FAILED = 'Users_Count_By_Country_FAILED';


export const Users_Count_By_City = 'Users_Count_By_City';
export const Users_Count_By_City_SUCCESS = 'Users_Count_By_City_SUCCESS';
export const Users_Count_By_City_FAILED = 'Users_Count_By_City_FAILED';

export const Users_Get = 'Users_Get';
export const Users_Get_SUCCESS = 'Users_Get_SUCCESS';
export const Users_Get_FAILED = 'Users_Get_FAILED';



