import { all, call, fork, put, takeEvery} from 'redux-saga/effects';
import * as constants from './constant'
import * as action from './action'
import { fetchJSON, optionPost } from '../../ApiUtils/apiUtils';
import { handleError } from '../../ApiUtils/handleError';
import { listUrl } from '../../ApiUtils/listUrl';
function* Report_User_Add({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, listUrl.reportUsers, optionPost(body));        
        yield put(action.Report_User_AddSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.Report_User_AddFailed(message));
    }
}
export function* watchReport_User_Add() {
    yield takeEvery(constants.Report_User_Add, Report_User_Add);
}
function* Saga() {
    yield all([
        fork(watchReport_User_Add),
    ]);
}
export default Saga;