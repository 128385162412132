import React from 'react';
import { PrivateRoute } from './PrivateRoute'
import { flattenRoutes } from './flattenRoutes'
const IndexComponent = React.lazy(() => import('../pages/index'));
const PanelComponent = React.lazy(() => import('../pages/Panel'));
const UsersComponent = React.lazy(() => import('../pages/Users'));
const StatisticsComponent = React.lazy(() => import('../pages/Statistics'));
const CitiesComponent = React.lazy(() => import('../pages/Cities'));
const CountryComponent = React.lazy(() => import('../pages/Country'));

const rootRoute = { path: '/', exact: true, component: IndexComponent, route: PrivateRoute };
const PanelRoute = { path: '/panel', component: PanelComponent }
const UsersRoute = { path: '/users', component: UsersComponent }
const StatisticsRoute = { path: '/statistics', component: StatisticsComponent }
const CitiesIdRoute = { path: '/cities/:id', component: CitiesComponent }
const CountryRoute = { path: '/country', component: CountryComponent }

const Superuser = [PanelRoute,UsersRoute,StatisticsRoute,CitiesIdRoute,CountryRoute]
const allRoutes = [rootRoute, ...Superuser];
const authProtectedRoutes = [...Superuser];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };