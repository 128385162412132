import * as constants from './constant'

const INIT_STATE = {
    loading: false,
};

const Reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case constants.Users_Count:
        case constants.Users_Get:
        case constants.Users_Count_By_Country:
        case constants.Users_Count_By_City:
            return { ...state, loading: true };
        case constants.Users_Count_FAILED:
        case constants.Users_Get_FAILED:
        case constants.Users_Count_By_Country_FAILED:
        case constants.Users_Count_By_City_FAILED:
            return { ...state, loading: false, error: action.payload };
        case constants.Users_Count_By_City_SUCCESS:
            return { ...state, CountUserByCity: action.payload, loading: false, error: null };
        case constants.Users_Count_By_Country_SUCCESS:
            return { ...state, CountUserByCountry: action.payload, loading: false, error: null };
        case constants.Users_Count_SUCCESS:
            return { ...state, CountUser: action.payload, loading: false, error: null };
        case constants.Users_Count_SUCCESS:
            return { ...state, CountUser: action.payload, loading: false, error: null };
        case constants.Users_Get_SUCCESS:
            return { ...state, ListUser: action.payload, loading: false, error: null };

        default:
            return { ...state };
    }
};

export default Reducer;
