import { jwtDecode } from "jwt-decode";
export const decodeToken = (token) => {
    try {
        const jwtToken = token;
        if (!jwtToken) { return null; }
        return jwtDecode(jwtToken);
    }
    catch {
        return null;
    }
}
export const isUserAuthenticated = () => {
    try {
        const jwtToken = getLoggedInUser();
        if (!jwtToken) { return false; }
        return true;
    }
    catch {
        return false;
    }
};
export const getLoggedInUser = () => {
    const user = localStorage.getItem("strayers_token");
    return user || null;
};
export const loading = () => {
    return (<div className="text-center"></div>)
}
export function isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}